import $ from 'jquery';
import { Spinner } from 'spin.js';
import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';

class Search {

    constructor() {
        var self = this;
        $(document).ready(function($) {
            $(document).on('opened.fndtn.reveal', '#searchModal', function() {
                $('.search-results-wrapper').css({
                    'display': 'block'
                });
                $('.search-input').focus();
            });

            $(document).on('close.fndtn.reveal', '#searchModal', function() {
                $('.search-results-wrapper').css({
                    'display': 'none'
                });

                $('body').removeClass('search-open');

                // @todo This can be removed.
                $('body').css({
                    'overflow': 'auto'
                });
            });

            // Mobile specific handling to make sure input is focused
            $('[data-search-modal]').on('click', function(e) {
                self.initializeSearch();

                $('body').addClass('search-open');

                // @todo This can be removed.
                $('body').css({
                    'overflow': 'hidden'
                });

                $('#searchModal').css({
                    'display':'block',
                    'visibility': 'visible',
                });
                $('.search-input').focus();

                $('#searchModal').foundation('reveal', 'open');

                e.preventDefault();
            });

        });

        $(document).foundation({
            reveal: {
                root_element: 'body',
                close_on_background_click: false,
                animation_speed : 0,
                animation : 0,
            }
        });
    }

    initializeSearch() {
        var ias;

        var spinnerOptions = {
            lines: 9, // The number of lines to draw
            length: 10, // The length of each line
            width: 3, // The line thickness
            radius: 4, // The radius of the inner circle
            scale: 1, // Scales overall size of the spinner
            corners: .5, // Corner roundness (0..1)
            color: '#051021', // #rgb or #rrggbb or array of colors
            fadeColor: 'transparent',
            opacity: 0, // Opacity of the lines
            rotate: 0, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            speed: 1, // Rounds per second
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            className: 'spinner', // The CSS class to assign to the spinner
            top: '50%', // Top position relative to parent
            left: '50%', // Left position relative to parent
            shadow: false, // Whether to render a shadow
            position: 'absolute', // Element positioning
            animation: 'spinner-line-shrink' // The CSS animation name for the lines
        };

        var spinner = new Spinner(spinnerOptions);

        // Intercept clicks on spelling results and reload via AJAX
        $('[data-search] [data-search-results]').on('click', '.spelling-suggestions a', function(e) {
            var searchTerm = $(this).text();

            $('[data-search-form] .search-input').val(searchTerm);
            $('[data-search-form]').trigger('pjaxsubmit');

            return false;
        });

        // On normal form submit or pjax, fire off the appropriate pjax request
        $('[data-search-form]').on('submit pjaxsubmit', function(event) {
            $.pjax.submit(event, '[data-search] [data-search-results]', {
                fragment: '.search-results-wrapper',
                scrollTo: false,
                timeout: 30000,
                push: false
            });
        });

        // Fade the container back while AJAX is underway
        $('[data-search] [data-search-results]').on('pjax:send', function(event, xhr, options) {

            $('[data-search-form] .search-submit').fadeOut(100, function() {
                $('[data-search-form] [data-spinner]').show();
                spinner.spin();
                $('[data-search-form] [data-spinner]').append(spinner.el);
            });
        });

        // Return to normal opacity when complete.
        // @todo This is global pjax behavior.  How should it change in light of that?
        $('[data-search] [data-search-results]').on('pjax:complete', function(event, xhr, textStatus, options) {

            // Hacky fix for page title mysteriously being set as "menu" after pjax call
            document.title = 'Search Results';

            $('[data-search-form] [data-spinner]').fadeOut(100, function() {
                spinner.stop();
                $('[data-search-form] .search-submit').fadeIn(100);
            });

            if (ias) {
                ias.unbind();
                ias.bind();
            } else {
                let ias = new InfiniteAjaxScroll($('.results-list')[0], {
                    scrollContainer: '#searchModal',
                    logger: true,
                    item: ".results-entry",
                    pagination: "nav.solr-pagination",
                    next: ".page-item-next a",

                    spinner: {
                        // element to show as spinner
                        element: '#loading-search-results',

                        // delay in milliseconds
                        // this is the minimal time the loader should be displayed. If loading takes longer, the spinner
                        // will be shown for the duration of the loading. If the loading takes less then this duration,
                        // say 300ms, then the spinner is still shown for 600ms.
                        delay: 200,

                        // this function is called when the button has to be shown
                        show: function(element) {
                            element.style.opacity = '1'; // default behaviour
                        },

                        // this function is called when the button has to be hidden
                        hide: function(element) {
                            element.style.opacity = '0'; // default behaviour
                        }
                    }
                });

                /*
                ias.on('last', function(event) {
                    document.getElementById('no-more-results').style.opacity = 1;
                });
                */
            }
        });
    }
}

export let search = new Search();
