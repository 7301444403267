import $ from 'jquery';
import { DateTime } from "luxon";
import Modernizr from 'modernizr';
import { user } from './user.js';
import { animation } from './animation.js';

require('foundation-sites/js/foundation/foundation.js');
require('foundation-sites/js/foundation/foundation.accordion.js');
require('foundation-sites/js/foundation/foundation.offcanvas.js');
require('foundation-sites/js/foundation/foundation.reveal.js');
require('foundation-sites/js/foundation/foundation.tab.js');

class PlanVisit {
    constructor() {
        if (document.querySelectorAll('[data-plan-your-visit]').length) {
            this.init();
        }
    }

    init() {
        var self = this;
        // On normal form submit or pjax, fire off the appropriate pjax request
        $('[data-plan-your-visit] form').on('submit, pjaxsubmit', function(event) {
            $.pjax.submit(event, '[data-plan-your-visit] [data-search-results]', {fragment: '[data-plan-your-visit] [data-search-results]', scrollTo: false});
        });

        // When a form element changes, fire pjax
        $('[data-plan-your-visit] form [data-update-on-change]').on('change', function() {
            var form = $(this).parents('form')[0];
            $(form).trigger('pjaxsubmit');
        });

        // When a form element changes, fire pjax
        $('[data-plan-your-visit] form [data-update-on-click]').on('click', function() {
            var form = $(this).parents('form')[0];
            $(form).trigger('pjaxsubmit');
        });

        // Handle topic checkboxes a little differently.  First clean up data, then submit.
        $('[data-plan-your-visit] form [data-topic-all]').on('change', function() {
            var isAllTopicCheckbox = $(this).attr('data-topic-all') == 1;
            var checked = $(this).is(':checked');

            if (isAllTopicCheckbox && checked) {
                // unset other checkboxes
                console.log('unsetting others');
                $('[data-plan-your-visit] form [data-topic-all=0]').prop('checked', false);
            } else if (isAllTopicCheckbox && !checked) {
                // don't do anything
            } else if (!isAllTopicCheckbox && checked) {
                // uncheck all topic checkbox
                console.log('unsetting all');
                $('[data-plan-your-visit] form [data-topic-all=1]').prop('checked', false);
            } else if (!isAllTopicCheckbox && !checked) {
                // don't do anything
            }

            var form = $(this).parents('form')[0];
            $(form).trigger('pjaxsubmit');
        });


        $('[data-plan-your-visit] [data-duration]').on('click', function(e){
            e.preventDefault();
            $('[data-duration]').removeClass('active');
            $(this).addClass('active')
            var value = parseInt($(this).attr('data-duration'));
            var valueInMinutes = value * 60;
            if (value === 8) {
                valueInMinutes = null;
            }
            $('[data-plan-your-visit] [data-duration-in-minutes]').val(valueInMinutes);
            var form = $(this).parents('form')[0];
            $(form).trigger('pjaxsubmit');
            $('[href="#step3"]').trigger('click');
        });

        // Fade the container back while AJAX is underway
        $('[data-plan-your-visit] [data-search-results]').on('pjax:send', function(event, xhr, options) {
            $(options.container).css('opacity', 0.1);
        });

        // Return to normal opacity when complete.
        $('[data-plan-your-visit] [data-search-results]').on('pjax:complete', function(event, xhr, textStatus, options) {
            $(options.container).css('opacity', 1);
            animation.reset();
        });



        $('[href="#step1"], [href="#step2"], [href="#step3"], [href="#step4"]').keydown(function(e) {
            if (e.keyCode === 13) {
                var $tab = $('[data-plan-your-visit] [data-tab] a[href="' + $(this).attr('href') + '"]');
                if ($(this).is('[data-plan-visit-nav]')) {
                    var $tab = $('[data-plan-your-visit] [data-tab] a[href="' + $(this).attr('href') + '"]');
                    $tab.trigger('click');
                    return false;
                } else {
                    self.deactivateActiveTab();
                    self.activateTab($tab.parent());
                }
            }
        }).click(function(e) {
            var $tab = $('[data-plan-your-visit] [data-tab] a[href="' + $(this).attr('href') + '"]');
            if ($(this).is('[data-plan-visit-nav]')) {
                var $tab = $('[data-plan-your-visit] [data-tab] a[href="' + $(this).attr('href') + '"]');
                $tab.trigger('click');
                return false;
            } else {
                self.deactivateActiveTab();
                self.activateTab($tab.parent());
            }
        });

        $('[data-plan-your-visit] .tab-title').click(function() {
            self.deactivateActiveTab();
        });

        $('[data-calendar-date]').on('click',function (e) {
           e.preventDefault();
           var dateAlias = $(this).attr('data-calendar-date');
            var dateObj = DateTime.fromObject({ hour: 0, minute: 0, second: 0, zone: 'America/New_York' });
            if(dateAlias == 'tomorrow'){
                dateObj = dateObj.plus({day:1});
            }

            var field = $('[data-datepicker]').find('input[type=text]').eq(0);
            field[0].value = dateObj.toFormat('M/d/yyyy');
            var form = $('[data-plan-your-visit] form')[0];
            $(form).trigger('pjaxsubmit');
            $('[href="#step2"]').trigger('click');
        });

        var now = DateTime.local();
        var time = parseInt(now.setZone('America/Chicago').toFormat('Hmm'));
        if (time > 1530) {
            $('[data-calendar-date="today"]').attr('style','display:none');
        }

        $('[data-calendar-open]').on('click',function (e) {
            e.preventDefault();
            $(this).next().removeAttr('style');
            $(this).attr('style','display:none')
        });

            // If there's a visit date in the cart, initialize plan your visit with it.
        var cartVisitDate = user.getVisitDate();
        if (cartVisitDate) {
            // @todo-upgrade Switched to Luxon
            var formattedDate = DateTime.fromISO(cartVisitDate, { zone: "America/Chicago" }).toFormat('M/d/yyyy');
            $('[data-plan-your-visit] #datepicker').val(formattedDate);
            var form = $('[data-plan-your-visit] form')[0];
            $(form).trigger('pjaxsubmit');
        }

        $('.tabs-plan-visit-tool-wrap [data-tab] a').click(function(){
            var current = $('[data-plan-your-visit] .content.active').attr('id');
            var currentNum = parseInt(current.substr(current.length-1));
            var next = $(this).attr('href');
            var nextNum = parseInt(next.substr(next.length-1));

            var lastActiveClasses;
            if (currentNum > nextNum) {
                lastActiveClasses = 'last-active slideOutLeft';
                $(next).addClass('slideInLeft').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
                    $(this).removeClass('slideInLeft');
                });
            } else {
                lastActiveClasses = 'last-active slideOutRight';
                $(next).addClass('slideInRight').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
                    $(this).removeClass('slideInRight');
                });
            }

            $('[data-plan-your-visit] .content.active').addClass(lastActiveClasses).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
                $(this).removeClass(lastActiveClasses);
            });

            if (!$('html').hasClass('cssanimations')) {
                $('[data-plan-your-visit] .content').removeClass(lastActiveClasses);
            }

        });

        /*$('#filterActivities').on('click',function (e) {
            e.preventDefault();
            $('form.tabs-plan-visit-tool').toggleClass('active');
        });*/

        $('#filterActivities, #show-results').on('click',function (e) {
            e.preventDefault();
            $('.tabs-plan-visit-tool-wrap').toggleClass('active');
        });
    }

    activateTab($element) {
        $element.addClass('animated activate').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
            $('[data-plan-your-visit] .tab-title a').attr('tabindex', -1);
            $('[data-plan-your-visit] [role=tabpanel]').attr('aria-hidden', false);

            $(this).removeClass('animated activate');
        });
    }

    deactivateActiveTab() {
        $('[data-plan-your-visit] .tab-title.active').addClass('animated deactivate').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
            $('[data-plan-your-visit] .tab-title a').attr('tabindex', -1);
            $('[data-plan-your-visit] [role=tabpanel]').attr('aria-hidden', false);

            $(this).removeClass('animated deactivate');
        });
    }

}

export let planVisit = new PlanVisit();
