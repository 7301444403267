(function($) {
    "use strict";

    $.fn.cycle.transitions.scrollHorzCustom = {
        before: function( opts, curr, next, fwd ) {
            opts.API.stackSlides( curr, next, fwd );
            var w = opts.container.css('overflow','hidden').width();
            opts.cssBefore = { left: fwd ? w : - w, top: 0, opacity: 1, visibility: 'visible', display: 'block' };
            opts.cssAfter = { zIndex: opts._maxZ - 2, left: 0 };
            opts.animIn = { left: 0 };
            opts.animOut = { left: fwd ? -w : w };

            setTimeout(function() {
                var nextThemeClass = $(next).find('[data-theme-class]').attr('data-theme-class');
                if (nextThemeClass) {
                    $('body').removeClass('first-theme-class').addClass(nextThemeClass);
                }

                var currentThemeClass = $(curr).find('[data-theme-class]').attr('data-theme-class');
                if (currentThemeClass) {
                    $('body').removeClass(currentThemeClass);
                }
            }, 400);
        }
    };

})(jQuery);


