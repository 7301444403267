import $ from 'jquery';

class UI {
    registerBoxTeaserEventHandler($boxTeaser) {
        $boxTeaser.focusin(
            /* Hover Start */
            function () {
                var $boxTeaserContent = $(this).find('.box-teaser-content');
                var displayHeight = $boxTeaserContent.outerHeight();
                var calculatedHeight = $boxTeaserContent[0].scrollHeight;
                var translateY = displayHeight - calculatedHeight;
                if (translateY < 0) {
                    $boxTeaserContent.css('transform', 'translate3d(0, ' + translateY + 'px, 0)');
                }
                $(this).addClass('box-teaser--focused');
                var $corner = $(this).find('.corner');
                $corner.addClass('corner--focused');
            }
        );
        $boxTeaser.focusout(
            /* Hover Stop */
            function () {
                var $boxTeaserContent = $(this).find('.box-teaser-content');
                var transform = $(this).css('transform');
                if (transform) {
                    $boxTeaserContent.css('transform', 'translate3d(0, 0, 0)');
                }
                $(this).removeClass('box-teaser--focused');
                var $corner = $(this).find('.corner');
                $corner.removeClass('corner--focused');
            }
        );
        $boxTeaser.hover(
            /* Hover Start */
            function () {
                var $boxTeaserContent = $(this).find('.box-teaser-content');
                var displayHeight = $boxTeaserContent.outerHeight();
                var calculatedHeight = $boxTeaserContent[0].scrollHeight;
                var translateY = displayHeight - calculatedHeight;
                if (translateY < 0) {
                    $boxTeaserContent.css('transform', 'translate3d(0, ' + translateY + 'px, 0)');
                }
            },
            /* Hover Stop */
            function () {
                var $boxTeaserContent = $(this).find('.box-teaser-content');
                var transform = $(this).css('transform');
                if (transform) {
                    $boxTeaserContent.css('transform', 'translate3d(0, 0, 0)');
                }
            }
        );
    }
}

export let ui = new UI();
