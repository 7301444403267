import $ from 'jquery';
import Glide from '@glidejs/glide'

require('alpinejs/dist/alpine-ie11.js')

class StickyFooter {


    constructor() {
        this.initialized = false;
        let resizing = null;
        if (window.matchMedia("only screen and (max-width: 1023px)").matches) {
            clearTimeout(resizing)
            resizing = setTimeout( () => {
                if (window.matchMedia("only screen and (max-width: 1023px)").matches) {
                    this.init();
                }
            }, 500);
        }
        $(window).resize(function () {
            clearTimeout(resizing)
            if(this.initialized == false){
                resizing = setTimeout( () => {
                    if (window.matchMedia("only screen and (max-width: 1023px)").matches) {
                        this.init();
                    }
                }, 500);
            }
        });

    }

    init(){
        this.initialized = true;
        window.dontOpenSideBar = false;

        if (window.matchMedia("only screen and (max-width: 1023px)").matches) {
            $('#page-inner-wrap').css('padding-bottom', $('.sticky-footer').outerHeight());
            $('<style id="padding-bottom-helper">.sticky-footer__panel{padding-bottom:'+$('.sticky-footer').outerHeight()+'px;}</style>').appendTo('body');
            window.dontOpenSideBar = true;

        }else{
            window.dontOpenSideBar = false;

            $('#page-inner-wrap').css('padding-bottom', 0);
        }
        let _this = this;
        let resizing = null;
        $(window).resize(function () {
            clearTimeout(resizing)
            resizing = setTimeout(function () {
                if (window.matchMedia("only screen and (max-width: 1023px)").matches) {
                    $('#page-inner-wrap').css('padding-bottom', $('.sticky-footer').outerHeight());
                    $('#padding-bottom-helper').remove();
                    $('<style id="padding-bottom-helper">.sticky-footer__panel{padding-bottom:'+$('.sticky-footer').outerHeight()+'px;}</style>').appendTo('body');
                    window.dontOpenSideBar = true;
                    let width = $('.sticky-footer-container .glide__slide:last-of-type').width();
                    $('.sticky-footer-container .glide__slide:last-of-type').width(width-6);
                } else {
                    $('#page-inner-wrap').css('padding-bottom', 0);
                    window.dontOpenSideBar = false;
                }
            }, 500);
        });
        this.initMobileCart();
    }


    initMobileCart() {
        window.MobileCart = {
            items:[],
            glide:null
        };
        window.MobileCart.glide = new Glide('[data-mobile-cart-items-list]', {
            startAt: 0,
            perView: 1,
            gap: 10,
            peek: 30,
        });
    }
    menu() {
        return {
            currentMenu: null,
            subMenu: null,
            items:[],
            totalItems: 0,
            sections: {
                visit:{},
                tickets:{},
                menu:{},
                search:{},
                location:{},
            },
            getNumber(){
                return this.totalItems;
            },
            close(){
                this.currentMenu=null;
                this.subMenu=null;
            },
            getClass(item){
                return (this.subMenu==item?'active':'hidden');
            },
            getClassForPanel(name){
                if(this.subMenu == null && this.currentMenu == name){
                    return 'active';
                }
                if(!(this.subMenu == null && this.currentMenu == name)){
                    return 'hidden';
                }
            },
            clear(){
                $('[data-mobile-cart-items-list] [data-addtovisit-delete-uid]').trigger('click');
                let bulletWrapper = document.getElementById('footer-glide__bullets');
                bulletWrapper.style="display:none";
                this.totalItems = 0;
                this.updateBullets()
            },
            updateBullets(){
                let self = this;
                let bulletWrapper = document.getElementById('footer-glide__bullets');
                setTimeout(function () {
                    self.updateItems();
                    let bulletCount = document.querySelectorAll('[data-mobile-cart-items-list] .glide__slide').length;
                    let bulletWrapper = document.getElementById('footer-glide__bullets');
                    bulletWrapper.innerHTML = '';
                    bulletWrapper.style="";

                    for (let index = 0; index < bulletCount; index++) {
                        const button = document.createElement('button');
                        button.className = 'glide__bullet';
                        button.setAttribute("data-glide-dir", '=' + index);
                        bulletWrapper.appendChild(button)
                    }
                    window.MobileCart.glide.mount();
                }, 2000);
            },
            updateItems(){
                let items = []
                $('.experience-wrap [data-addtovisit-uid]').each(function(i,e){
                    items.push(e.outerHTML);
                });
                this.items = items;
                this.totalItems = this.items.length;
                this.$nextTick(()=> {
                    this.$nextTick(()=>{
                        let width = $('.sticky-footer-container .glide__slide:last-of-type').width();
                        $('.sticky-footer-container .glide__slide:last-of-type').width(width - 6);
                    });
                });
            },
            toggle(menu){
                let self = this;
                if(menu == 'search'){
                    this.subMenu = null;
                    if($('.reveal-modal.open').length == 0){
                        $('[data-search-modal]').trigger('click');
                    }
                    this.currentMenu = (this.currentMenu == menu) ? null : menu;
                } else {
                    this.subMenu = null;
                    this.currentMenu = (this.currentMenu == menu) ? null : menu;
                    this.$nextTick(()=>{
                        $('[data-close]').trigger('click');
                        if (menu == 'visit'){
                            self.updateItems();

                            this.$nextTick(()=>{
                                let bulletCount = document.querySelectorAll('[data-mobile-cart-items-list] .glide__slide').length;
                                let bulletWrapper = document.getElementById('footer-glide__bullets');
                                bulletWrapper.innerHTML = '';
                                bulletWrapper.style="";

                                for (let index = 0; index < bulletCount; index++) {
                                    const button = document.createElement('button');
                                    button.className = 'glide__bullet';
                                    button.setAttribute("data-glide-dir", '=' + index);
                                    bulletWrapper.appendChild(button)
                                }
                                window.MobileCart.glide.mount();



                            });

                        }
                    });
                }
            },
            showSubMenu(submenu){
                this.subMenu = submenu;
            }
        }
    }
}
export let StickyFooterMobile = new StickyFooter();
