import { DateTime } from "luxon";
import Url from 'domurl';
import Cookies from 'js-cookie';

var domain = process.env.NODE_ENV == 'development' ? process.env.MIX_COOKIE_DOMAIN :  '.msichicago.org'

class NonTicketedExperiences {

    reset() {
        this.removeCookie();
    }

    getExperienceIds() {
        var data = this.readCookie();
        return data;
    }

    addExperience(experienceId) {
        var data = this.readCookie();
        if (data.indexOf(experienceId) == -1) {
            data.unshift(experienceId);
            this.writeCookie(data);
        }
    }

    removeExperience(experienceId) {
        var data = this.readCookie();
        var index = data.indexOf(experienceId);
        if (index > -1) {
            data.splice(index, 1);
            this.writeCookie(data);
        }
    }

    readCookie() {
        var data = Cookies.getJSON('nonTicketedExperiences');
        if (!data) {
            data = [];
        }

        return data;
    }

    writeCookie(data) {
        Cookies.set('nonTicketedExperiences', data, { domain: domain });
    }

    removeCookie() {
        Cookies.remove('nonTicketedExperiences', { domain: domain });
    }
}

class Ticketure {

    reset() {
        this.removeCookie();
    }

    getVisitDate() {
        var data = this.readCookie();
        var date = null;
        if (data) {
            date = data.date;
            if (date) {
                date = DateTime.fromFormat(date, 'yyyy-M-d', {zone: 'America/Chicago'}).toFormat('yyyy-M-d');
            }
        }

        return date;
    }

    setVisitDate(date) {
        var data = this.readCookie();
        data.date = (date !== null) ? DateTime.fromJSDate(new Date(date), { zone: 'America/Chicago'}).toFormat('yyyy-M-d') : null;
        this.writeCookie(data);
    }

    removeVisitDate() {
        return this.setVisitDate(null);
    }

    getEventIds() {
        var data = this.readCookie();
        var events = [];
        if (data) {
            events = data.events;
        }

        return events;
    }

    addEvent(eventId) {
        var data = this.readCookie();
        if(data.events.indexOf(eventId) == -1){
            data.events.unshift(eventId);
            this.writeCookie(data);
        }
    }

    removeEvent(eventId) {
        var data = this.readCookie();
        var index = data.events.indexOf(eventId);
        if (index > -1) {
            data.events.splice(index, 1);
            this.writeCookie(data);
        }
    }

    readCookie() {
        var data = Cookies.getJSON('intent');
        if (!data) {
            data = {
                date: null,
                events: []
            };
        }

        return data;
    }

    writeCookie(data) {
        if (data.date === null) {
            delete data.date;
        }
        Cookies.set('intent', data, { domain: domain });
        //Cookies.set('intent', data);
    }

    removeCookie() {
        Cookies.remove('intent');
    }

    saveCodeToCookie(code) {
        Cookies.set('code', code, { domain: domain });
    }

};

class User {

    constructor() {
        this.Ticketure = new Ticketure();
        this.NonTicketedExperiences = new NonTicketedExperiences();

        let url = new Url();
        let code = url.query.code;
        if (code) {
            this.Ticketure.saveCodeToCookie(code);
        }
    }

    getCart() {
        var data = this.readCookie();
        return data;
    }

    addToCart(experienceId, ticketureId) {
        var data = this.readCookie();
        if (data.indexOf(experienceId) == -1) {
            data.unshift(experienceId);
            this.writeCookie(data);
        }

        if (ticketureId) {
            this.Ticketure.addEvent(ticketureId);
        } else {
            this.NonTicketedExperiences.addExperience(experienceId);
        }
    }

    removeFromCart(experienceId, ticketureId) {
        var data = this.readCookie();
        var index = data.indexOf(experienceId);
        if (index > -1) {
            data.splice(index, 1);
            this.writeCookie(data);
        }

        if (ticketureId) {
            this.Ticketure.removeEvent(ticketureId);
        } else {
            this.NonTicketedExperiences.removeExperience(experienceId);
        }
    }

    setVisitDate(visitDate) {
        this.Ticketure.setVisitDate(visitDate);
    }

    getVisitDate() {
        return this.Ticketure.getVisitDate();
    }

    removeVisitDate() {
        this.Ticketure.removeVisitDate();
    }


    isLocalStorageAvailable() {
        if (typeof this.localStorageAvailable === 'undefined') {
            var test = 'MSIUserTest';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                this.localStorageAvailable = true;
            } catch (e) {
                this.localStorageAvailable = false;
            }
        }

        return this.localStorageAvailable;
    }

    readCookie() {
        var data = Cookies.getJSON('MSICart');
        if (!data) {
            data = [];
        }

        return data;
    }

    writeCookie(data) {
        Cookies.set('MSICart', data, { domain: domain });
    }

    removeCookie() {
        Cookies.remove('MSICart', { domain: domain });
    }
};

export let user = new User();
