import Glide from '@glidejs/glide';
import $ from 'jquery';
class GlideInstances {

    constructor() {
        this.instances = [];
        this.membershipInstances = [];
    }

    createGlideMembership(selector){

        let totalSlides = $(selector).find('.glide__slide').length;
        let startAt = 0;
        if(totalSlides % 2 === 0){
            startAt = (totalSlides / 2) - 1;
        }else{
            startAt = (totalSlides / 2);
            startAt = Math.floor(startAt);
        }
        this.membershipInstances[selector] = new Glide(selector, {
            type: 'slider',
            startAt: startAt,
            perView: 1,
            focusAt:'center',
            gap: 0,
            peek:0,
            breakpoints: {
                5000: {
                    perView: 5,
                    peek: 0,
                },
                1910: {
                    perView: 4,
                    peek: 50,
                },
                1700: {
                    perView: 3,
                    gap: 30,
                    peek: 150,
                },
                1350: {
                    perView: 3,
                    gap: 30,
                    peek: 0,
                },
                1050: {
                    perView: 2,
                    gap:10,
                    peek:10,
                },
                700:{
                    perView: 1,
                    gap: 10,
                    peek: 100,
                    startAt: 2,
                },
                600:{
                    perView: 1,
                    gap: 10,
                    peek:50,
                },
                400:{
                    perView: 1,
                    gap: 0,
                    peek: 0,
                    startAt: 2,
                }
            }
        }).mount();

    }

    createGlide(selector, options = {
        startAt: 0,
        perView: 3,
        gap: 10,
        peek: 30,
        breakpoints:{
           /* 600: {
                gap: 10,
                peek: 30,
                perView: 1
            },
            1023:{
                gap: 10,
                peek: 30,
                perView: 2,
            },
            4000:{
                perView: 3,
                gap: 40,
                peek: 0,
            }

            */
            6000: {
                perView: 3,
                gap: 40,
                peek: 0,
            },
            1299: {
                perView: 3,
                gap: 40,
                peek:0,
            },
            1240: {
                perView: 3,
                gap: 20,
                peek:0,
            },
            1100: {
                perView: 3,
                gap: 30,
                peek: 50,
            },
            1024: {
                perView: 2,
                gap: 30,
                peek: 80,

            },
            930: {
                perView: 2,
                gap: 30,
                peek: 50,
            },
            767: {
                perView: 2,
                gap: 30,
                peek: 50,
            },
            640: {
                perView: 2,
                gap: 30,
                peek: 10,
            },
            600: {
                perView: 1,
                gap: 30,
                peek: 80,
            },
            540: {
                perView: 1,
                gap: 30,
                peek: 80,
            },
            480: {
                perView: 1,
                gap: 30,
                peek: 50,
            },
            400: {
                perView: 1,
                gap: 30,
                peek: 40,
            }

        }
    }) {

        this.instances[selector] = new Glide(selector, options).mount();
        this.instances[selector].disable();
        $(selector).find('.glide__bullets').attr('style','display:none')

    }

    enableAll() {
        for(var index in this.instances){
            this.instances[index].enable()
            $(index).find('.glide__bullets').removeAttr('style');
        }
    }
    disableAll(){
        let _this = this;
        for(var index in this.instances){
            $(index).find('.glide__bullets').attr('style','display:none');
            this.instances[index].go('<<');
            setTimeout(function(){
                _this.instances[index].disable();
            },500)
        }
    }
    responsive(){
        if (window.matchMedia("only screen and (max-width: 1023px)").matches) {
            this.enableAll()
        }else{
            this.disableAll()
        }
        let _this = this;
        let resizing = null;
        $( window ).resize(function() {
            clearTimeout(resizing)
            resizing = setTimeout(function(){
                if (window.matchMedia("only screen and (max-width: 1023px)").matches) {
                    _this.enableAll()
                }else{
                    _this.disableAll()
                }
            },500);
        });
    }
}

export let glide = new GlideInstances();
