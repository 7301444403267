$.fn.cycle.API.move = function(slide, fromIndex, toIndex ) {
    var opts = this.opts();
    var $slide = $(slide);

    opts.slides.splice(toIndex, 0, opts.slides.splice(fromIndex, 1)[0]);
    $slide.insertAfter(opts.container.find('.cycle-slide:not(".cycle-sentinel")').get(toIndex - 1));
    opts.slideCount++;
    var slideOpts = opts.API.buildSlideOpts( $slide );

    opts.API.initSlide( slideOpts, slide, --opts._maxZ );

    $slide.data('cycle.opts', slideOpts);
    opts.API.trigger('cycle-slide-added', [ opts, slideOpts, $slide ]);
    opts.API.stackSlides( opts.slides[opts.currSlide], opts.slides[opts.nextSlide], !opts.reverse );

    opts.API.updateView( true );
};

$.fn.cycle.API.hide = function(index) {
    var opts = this.opts();
    var slide, slideToRemove, slides = [], slideNum = 1;
    for ( var i=0; i < opts.slides.length; i++ ) {
        slide = opts.slides[i];
        if ( i == index ) {
            slideToRemove = slide;
        }
        else {
            slides.push( slide );
            $( slide ).data('cycle.opts').slideNum = slideNum;
            slideNum++;
        }
    }
    if ( slideToRemove ) {
        opts.slides = $( slides );
        opts.slideCount--;
        $( slideToRemove ).remove();
        if (index == opts.currSlide)
            opts.API.advanceSlide( 1 );
        else if ( index < opts.currSlide )
            opts.currSlide--;

        opts.API.trigger('cycle-slide-removed', [ opts, index, slideToRemove ]).log('cycle-slide-removed');
        opts.API.updateView();
    }


};
