import $ from 'jquery';
require('icheck/icheck.js');

class FilterableList {

    constructor() {
        if (document.querySelectorAll('[data-filterable-list]').length) {
            this.init();
        }
    }

    init() {
        var self = this;
        $(document).ready(function () {

            //icheck for what's here filter
            $('[data-filterable-list] input').iCheck({
                checkboxClass: 'icheckbox_minimal-green',
                radioClass: 'iradio_minimal-green',
                aria: true
            });

            // Initialize filter labels.
            document.querySelectorAll('[data-filter]').forEach((element, index) => {
                self.updateFilterLabels($(element));
            });

            $('[data-filter]').focusin(function () {
                $(this).addClass('filter--focused');
                // $(this).find('.filter-close-label').find('.button').attr("tabindex",0);
            });

            // On normal form submit or pjax, fire off the appropriate pjax request
            $('[data-filterable-list] form').on('submit, pjaxsubmit', function (event) {
                $.pjax.submit(event, '[data-filterable-list] [data-search-results]', {
                    fragment: '[data-filterable-list] [data-search-results]',
                    scrollTo: false
                });
            });

            // When a form element changes, fire pjax
            $('[data-filterable-list] form [data-update-on-change]').on('ifChecked', function (e) {
                var $parent = $(this).parents('[data-filter]');

                if ($(this).data('select-all')) {
                    // If select all was checked, disable others.
                    $parent.find('input:not([data-select-all])').prop('checked', false).iCheck('update');
                } else {
                    // Otherwise, disable select all
                    $parent.find('[data-select-all]').prop('checked', false).iCheck('update');
                }

                self.updateFilterLabels($parent);

                var form = $(this).parents('form')[0];
                $(form).trigger('pjaxsubmit');
            });

            $('[data-filterable-list] form [data-update-on-change]').on('ifUnchecked', function (e) {
                var $parent = $(this).parents('[data-filter]');

                // Nothing selected? Pick "all"
                var $checked = $parent.find(':checked').not($(this));
                if ($checked.length === 0) {
                    $parent.find('[data-select-all]').prop('checked', true).iCheck('update');
                }

                self.updateFilterLabels($parent);

                var form = $(this).parents('form')[0];
                $(form).trigger('pjaxsubmit');
            });

            // Fade the container back while AJAX is underway
            $('[data-filterable-list] [data-search-results]').on('pjax:send', function (event, xhr, options) {
                $(options.container).css('opacity', 0.1);
            });

            // Return to normal opacity when complete.
            $('[data-filterable-list] [data-search-results]').on('pjax:complete', function (event, xhr, textStatus, options) {
                $(options.container).css('opacity', 1);
                MSI.Animations.reset();
            });

            // Open the list and scroll to it when a filter is clicked
            $('.filter-container .filter > a').click(function () {
                $('.filter-container [data-filter]').removeClass('hover');
                if (window.matchMedia("only screen and (max-width: 599px)").matches) {
                    $('body').addClass('no-scroll');
                }

                var target = $(this).parent();
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 500);
                }

                $(this).parent().addClass('hover');
            });

            // Close the list when close button is clicked
            // function closeFilterButton(button) {
            //     $('body').removeClass('no-scroll');
            // 	var selector = $(button).attr('data-filterable-list-close');
            // 	$('.filter-container [data-filter=' +selector+ ']').removeClass('hover');
            //     $('.filter-container [data-filter=' +selector+ ']').removeClass('filter--focused');
            //     $(button).removeAttr("tabindex");
            // }

            // $('a[data-filterable-list-close]').click(function(){ closeFilterButton(this); });
            // $('#filterable-list-plugin').on('keypress', 'a[data-filterable-list-close]', function(e) {
            //     if(e.keyCode === 13) {
            //         closeFilterButton(this);
            //     }
            // });

            // Exit on escape
            $('#filterable-list-plugin .filter').on('keyup', function (e) {
                if (e.keyCode === 27) {
                    self.closeFilter(this);
                }
            });

            // Exit after losing focus
            $('#filterable-list-plugin .filter').focusout(function () {
                var container = this;
                setTimeout(function () {
                    if ($(container).has(document.activeElement).length <= 0) {
                        self.closeFilter(container);
                    }
                }, 0);
            });

            // END DOC READY
        });
    }

    // Updates filter labels based on selections
    updateFilterLabels($filterContainer) {
        var newLabel;
        var $label = $filterContainer.find('[data-label]');

        var $selectAllChecked = $filterContainer.find('[data-select-all]').prop('checked');

        if ($selectAllChecked) {
            newLabel = $label.data('all-label');
        } else {
            var $checked = $filterContainer.find(':checked');
            if ($checked.length === 0) {
                newLabel = $label.data('all-label');
            } else if ($checked.length === 1) {
                newLabel = $checked.parents('label').text();
            } else {
                newLabel = $label.data('plural-label');
                newLabel = newLabel.replace(/%s/g, $checked.length);
            }
        }

        $label.text(newLabel);
    }

    closeFilter(filter) {
        $('body').removeClass('no-scroll');
        $(filter).removeClass('hover');
        $(filter).removeClass('filter--focused');
        // $('a[data-filterable-list-close]').removeAttr("tabindex");
    }
}

export let filterableList = new FilterableList();
