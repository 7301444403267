/*! scrollVertUp transition plugin for Cycle2 */
(function($) {
"use strict";

$.fn.cycle.transitions.scrollVertUp = {
    before: function( opts, curr, next, fwd ) {
        opts.API.stackSlides( opts, curr, next, fwd );
        var mediaQuery = matchMedia("(max-width: 768px)");
        if (!Modernizr.cssanimations) {
            var height = opts.container.css('overflow','hidden').height();
            opts.cssBefore = { bottom: fwd ? -height : height, top: fwd ? height : -height, left: 0, opacity: 1, display: 'block', visibility: 'visible' };
            opts.animIn = { bottom: fwd ? -height : height, top: 0 };
            opts.animOut = { bottom: fwd ? height : -height, top: fwd ? -height : height };
        } else {
            var animationClasses = 'animated bigHeaderSlideUpOut';
            if ($(curr).find('.bh__background').length) {
                $(curr).find('.bh__background').removeClass('slideInUpBig').addClass('animated slideOutUpBig');
            }
            if ($(curr).find('.bh__content').length) {
                $(curr).find('.bh__subtitle .line').removeClass('bigHeaderSlideUpIn').addClass(animationClasses);
                $(curr).find('.bh__title .line').removeClass('bigHeaderSlideUpIn').addClass(animationClasses);
                $(curr).find('.bh__tagline .line').removeClass('bigHeaderSlideUpIn').addClass(animationClasses);
                $(curr).find('.bh__links').removeClass('bigHeaderSlideUpInLink').addClass('animated fadeOutFaster');
                $(curr).find('.bh__description > *').removeClass('bigHeaderSlideUpInLink').addClass('animated fadeOutFaster');
            }
            if ($(next).find('.bh__background ').length) {
                $(next).find('.bh__background').addClass('animated slideInUpBig');
            }
            if ($(next).find('.bh__content ').length) {
                $(next).find('.bh__subtitle .line').addClass('animated bigHeaderSlideUpIn');
                $(next).find('.bh__title .line').addClass('animated bigHeaderSlideUpIn');
                $(next).find('.bh__tagline .line').addClass('animated bigHeaderSlideUpIn');
                $(next).find('.bh__links').addClass('animated bigHeaderSlideUpInLink');
                $(next).find('.bh__description > *').addClass('animated bigHeaderSlideUpInLink');
            }
        }

        setTimeout(function() {
            var nextThemeClass = $(next).find('[data-theme-class]').attr('data-theme-class');
            if (nextThemeClass) {
                $('body').removeClass('first-theme-class').addClass(nextThemeClass);
            }

            var currentThemeClass = $(curr).find('[data-theme-class]').attr('data-theme-class');
            if (currentThemeClass) {
                $('body').removeClass(currentThemeClass);
            }
        }, 400);

    },

    after: function( opts, curr, next, fwd ) {
        var animationClasses = 'animated bigHeaderSlideUpOut slideOutUpBig fadeOutFaster';
        $(curr).find('.bh__background').removeClass(animationClasses);
        $(curr).find('.bh__subtitle .line').removeClass(animationClasses);
        $(curr).find('.bh__title .line').removeClass(animationClasses);
        $(curr).find('.bh__tagline .line').removeClass(animationClasses);
        $(curr).find('.bh__links').removeClass(animationClasses);
        $(curr).find('.bh__description > *').removeClass(animationClasses);
    }
};

})(jQuery);
