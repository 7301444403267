/*! slideVertUp transition plugin for Cycle2 */
(function($) {
"use strict";

$.fn.cycle.transitions.slideVertUp = {
    before: function( opts, curr, next, fwd ) {
        opts.API.stackSlides( opts, curr, next, fwd );

        if ($('html').hasClass('cssanimations')) {
            if ($(curr).find('.content-area').length) {
                $(curr).find('.subtitle .line').removeClass('slideInUpSmallFast').addClass('animated slideOutUpSmallFast');
                $(curr).find('.date .line').removeClass('slideInUpSmallFast').addClass('animated slideOutUpSmallFast');
                $(curr).find('.title .line').removeClass('slideInUpSmallFast').addClass('animated slideOutUpSmallFast');
                $(curr).find('.text .line').removeClass('slideInUpSmallFast').addClass('animated slideOutUpSmallFast');
            }
            if ($(next).find('.content-area ').length) {
                $(next).find('.subtitle .line, .date .line, .title .line, .text .line').addClass('animatable');

                setTimeout(function() {
                    $(next).find('.subtitle .line').addClass('animated slideInUpSmallFast').removeClass('animatable');
                    $(next).find('.date .line').addClass('animated slideInUpSmallFast').removeClass('animatable');
                    $(next).find('.title .line').addClass('animated slideInUpSmallFast').removeClass('animatable');
                    $(next).find('.text .line').addClass('animated slideInUpSmallFast').removeClass('animatable');
                }, (parseInt(opts.speed) / 4));
            }
        } else {
            // Copied from scrollVertUp
            var height = opts.container.css('overflow','hidden').height();
            opts.cssBefore = { bottom: fwd ? -height : height, top: fwd ? height : -height, left: 0, opacity: 1, display: 'block', visibility: 'visible' };
            opts.animIn = { bottom: fwd ? -height : height, top: 0 };
            opts.animOut = { bottom: fwd ? height : -height, top: fwd ? -height : height };
        }
    },

    after: function( opts, curr, next, fwd ) {
        $(curr).find('.subtitle .line').removeClass('animatable animated slideOutUpSmallFast');
        $(curr).find('.date .line').removeClass('animatable animated slideOutUpSmallFast');
        $(curr).find('.title .line').removeClass('animatable animated slideOutUpSmallFast');
        $(curr).find('.text .line').removeClass('animatable animated slideOutUpSmallFast');
    }
};

})(jQuery);
