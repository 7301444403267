// New initialization
window.Modernizr = require("modernizr");

import cssVars from 'css-vars-ponyfill';
import $ from 'jquery';
import 'what-input'
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
jQueryBridget( 'masonry', Masonry, $ );

require('waypoints/lib/jquery.waypoints.js');
window.ProgressBar = require('progressbar.js/dist/progressbar');
require('jquery.cycle2/src/jquery.cycle2.min.js');
// @todo-upgrade These are not available in the NPM shim we're using.
//require('jquery.cycle2/build/jquery.cycle2.scrollVert.min.js');
//require('jquery.cycle2/build/jquery.cycle2.center.min.js');

require('jquery-pjax');
require('jquery.easing');
require('jquery-hoverintent');
require('vide/src/jquery.vide.js');
require('fancybox/dist/js/jquery.fancybox.js');
require('clamp-js/clamp.js');
require('iframe-resizer/js/iframeResizer.js');
require('jquery-match-height/jquery.matchHeight.js');

let imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );

// @todo-upgrade  iS this properly initialized with jquery support?
import Pikaday from 'pikaday';
require('pikaday/plugins/pikaday.jquery.js');

import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import * as Ladda from 'ladda';


require('foundation-sites/js/foundation/foundation.js');
require('foundation-sites/js/foundation/foundation.accordion.js');
require('foundation-sites/js/foundation/foundation.offcanvas.js');
require('foundation-sites/js/foundation/foundation.reveal.js');
require('foundation-sites/js/foundation/foundation.slider.js');
require('foundation-sites/js/foundation/foundation.tab.js');

require('./jquery.cycle.custom.js');
require('./jquery.cycle2.scrollHorzRight');
require('./jquery.cycle2.scrollHorzCustom');
require('./jquery.cycle2.scrollVertUp');
require('./jquery.cycle2.slideUp');
require('./jquery.cycle2.slideVertUp');

window.MSI = window.MSI || {};

import { animation } from './animation.js'
import { visit } from './visit.js';
import { skin } from './skin.js';
import { megaMenu } from './megamenu.js';
import { search } from './search.js';
import { user } from './user.js';
import { planVisit } from './plan-visit.js';
import { filterableList } from './filterable-list.js';
import { faq } from './faq.js';
import { glide } from './glide.js';
import { StickyFooterMobile } from './sticky-footer.js';
import { ui } from './ui.js';

MSI.Animations = animation;
MSI.MegaMenu = megaMenu;
MSI.Search = search;
MSI.Skin = skin;
MSI.User = user;
MSI.Visit = visit;
MSI.PlanYourVisit = planVisit;
MSI.FilterableList = filterableList;
MSI.FAQ = faq;
MSI.Glide = glide;
MSI.StickyFooterMobile = StickyFooterMobile;
MSI.UI = ui;

setTimeout(function(){
    MSI.Glide.responsive();
},500)

cssVars();

