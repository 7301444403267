/*! scrollHorzRight transition plugin for Cycle2 */
(function($) {
"use strict";

$.fn.cycle.transitions.scrollHorzRight = {
    before: function( opts, curr, next, fwd ) {
        // The first slide is initialize as left: 0 which breaks the transition.  Need to force it to left: auto instead.
        $(curr).css('left', 'auto');

        opts.API.stackSlides( curr, next, fwd );
        var w = opts.container.css('overflow','hidden').width();
        opts.cssBefore = { right: fwd ? w : - w, top: 0, left: 'auto', opacity: 1, visibility: 'visible', display: 'block' };
        opts.cssAfter = { zIndex: opts._maxZ - 2, right: 0, left: 'auto' };
        opts.animIn = { right: 0 };
        opts.animOut = { right: fwd ? -w : w };
    }
};

})(jQuery);
