import $ from 'jquery';
import Modernizr from 'modernizr';

class Animation {
    constructor() {
        this.offset = '70%';
        this.isEnabled = false;

        window.addEventListener('load', (event) => {
            this.init();
        });
    }

    init() {
        let self = this;
        let mediaQuery = matchMedia("(max-width: 768px)");
        if ((!mediaQuery || mediaQuery.matches) || !Modernizr.cssanimations) {
            this.isEnabled = false;
        } else {
            this.isEnabled = true;
        }

        document.querySelectorAll('.layout-container').forEach((element, index) => {
            let $element = $(element);

            let imageAnimation = $element.attr('data-image-animation');
            let contentAnimation = $element.attr('data-content-animation');


            if (imageAnimation || contentAnimation) {
                let allContentElements = $element.find('.lc__content .columns .lc__content-inner-wrap > *');

                // Only one content element which is text and images, actually has images, and does not have a background image
                let layoutContainerHasOneTextAndImagesContentElement = (allContentElements.length === 1) && allContentElements.hasClass('text-and-images') && ($element.find('.tai__image-group-wrap').length === 1) && ($(this).find('.lc__background[style], .lc__background[data-bgset]').length === 0);

                let elementsToAnimate = this.getHeadlineAnimationElements($element);
                if (layoutContainerHasOneTextAndImagesContentElement) {
                    if (contentAnimation) {
                        elementsToAnimate.push({
                            elements: $element.find('.lc__content .columns .lc__content-inner-wrap section .ce-content'),
                            animation: contentAnimation
                        });
                    }

                    if (imageAnimation) {
                        elementsToAnimate.push({
                            elements: $element.find('.tai__image-group-wrap'),
                            animation: imageAnimation
                        });
                    }

                } else {
                    if (imageAnimation) {
                        elementsToAnimate.push({
                            elements: $element.find('.lc__background'),
                            animation: imageAnimation
                        });
                    }

                    if (contentAnimation) {
                        elementsToAnimate.push({
                            wrapper: $element.find('.lc__content .columns .lc__content-inner-wrap')
                        });

                        elementsToAnimate.push({
                            elements: $element.find('.lc__content .columns .lc__content-inner-wrap > section, .lc__content .columns .lc__content-inner-wrap > div'),
                            animation: contentAnimation
                        });
                    }
                }

                let $tagline = $element.find('.lc__tagline-wrap');
                if ($tagline.length) {
                    elementsToAnimate.push({
                        elements: $tagline.find('.lc__tagline-text, .lc__tagline-number'),
                        animation: 'fadeIn'
                    });
                    elementsToAnimate.push({
                        elements: $tagline.find('.lc__tagline-line'),
                        animation: 'fadeInDown'
                    });
                }

                this.enableAnimation($element, elementsToAnimate, element.offset, $element.find('.lc__content .columns .lc__content-inner-wrap'));
            }
        });

        /**
         * One off animation for "Bringing science to life" on home page
         */
        document.querySelectorAll('#c4813').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [{
                wrapper: $element.find('.ce-content')
            }, {
                elements: $element.find('.headline-group, p'),
                animation: 'fadeInAndSlideInLeft'
            }];

            this.enableAnimation($element, elementsToAnimate);
        });

        /**
         * One off animation for "Our Location" on home page
         */
        document.querySelectorAll('#c4815').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [{
                elements: $element.find('h3'),
                animation: 'fadeIn'
            }, {
                elements: $element.find('h2'),
                animation: 'fadeInAndSlideInLeft'
            }, {
                elements: $element.find('.direction-link'),
                animation: 'bigFadeInAndSlideInLeft'
            }];

            this.enableAnimation($element, elementsToAnimate);
        });

        /**
         * Specific animation for main and sidebar content on various exhibit home pages
         */
        document.querySelectorAll('.lc__exhibit-home-intro').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [{
                wrapper: $element.find('.lc__intro-content')
            }, {
                wrapper: $element.find('.lc__sidebar #all-essentials')
            }, {
                elements: $element.find('.lc__intro-content nav, .lc__intro-content .ce-content > *'),
                animation: 'fadeInAndSlideInLeft'
            }, {
                elements: $element.find('.lc__sidebar #all-essentials li'),
                animation: 'fadeInAndSlideInRight'
            }];

            this.enableAnimation($element, elementsToAnimate);
        });

        /**
         * Specific animation for experience landing
         */
        document.querySelectorAll('ul.experience-landing-teasers').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [
                {
                    elements: $element,
                    animation: 'slideInUp'
                }
            ];

            this.enableAnimation($element, elementsToAnimate, (window.innerHeight || document.documentElement.clientHeight) - 100);
        });

        // Specific animation for footer box teasers in glide

        document.querySelectorAll('[data-content-animation="slideInUp"] li.glide__slide').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [
                {
                    elements: $element,
                    animation: 'slideInUp'
                }
            ];

            this.enableAnimation($element, elementsToAnimate);
        });


        document.querySelectorAll('#c3756').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [
                {
                    wrapper: $element.find('.experience-landing-intro')
                },
                {
                    elements: $element.find('.experience-landing-intro > *'),
                    animation: 'fadeInAndSlideInLeft'
                }
            ];

            this.enableAnimation($element, elementsToAnimate);
        });

        document.querySelectorAll('#c3758').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [
                {
                    wrapper: $element.find('.experience-landing-intro')
                },
                {
                    elements: $element.find('.experience-landing-intro > *'),
                    animation: 'fadeInAndSlideInRight'
                }
            ];

            this.enableAnimation($element, elementsToAnimate);
        });

        document.querySelectorAll('#c3759').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [
                {
                    wrapper: $element.find('.experience-landing-intro')
                },
                {
                    elements: $element.find('.experience-landing-intro > *'),
                    animation: 'fadeInAndSlideInLeft'
                }
            ];

            this.enableAnimation($element, elementsToAnimate);
        });

        document.querySelectorAll('#c3757').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [
                {
                    wrapper: $element.find('.experience-landing-intro')
                },
                {
                    elements: $element.find('.experience-landing-intro > *'),
                    animation: 'fadeInAndSlideInRight'
                }
            ];

            this.enableAnimation($element, elementsToAnimate);
        });

        document.querySelectorAll('[data-filterable-list] [data-search-results] li').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [
                {
                    elements: $element,
                    animation: 'slideInUp'
                }
            ];

            this.enableAnimation($element, elementsToAnimate, (window.innerHeight || document.documentElement.clientHeight) - 100);
        });

        document.querySelectorAll('[data-plan-your-visit] [data-search-results] li').forEach((element, index) => {
            let $element = $(element);

            let elementsToAnimate = [
                {
                    elements: $element,
                    animation: 'slideInUp'
                }
            ];

            this.enableAnimation($element, elementsToAnimate, (window.innerHeight || document.documentElement.clientHeight) - 100);
        });
    }

    enableAnimation($container, elements, offset, $trigger) {
        if (offset === undefined) {
            offset = this.offset;
        }
        if (!$trigger || !$trigger.length) {
            $trigger = $container;
        }

        if (this.isEnabled && !this.isElementInViewport($container)) {
            $.each(elements, function() {
                if (this.wrapper) {
                    this.wrapper.addClass('animation-wrapper');
                } else {
                    this.elements.addClass('animatable').attr('data-animation', this.animation);
                }
            });

            new Waypoint({
                element: $trigger,
                handler(direction) {
                    if ($container.hasClass('animatable') && $container.attr('data-animation')) {
                        let animation = $container.attr('data-animation');
                        $container.addClass(animation).addClass('animated');
                    }

                    $container.find('.animatable').each(function() {
                        let animation = $(this).attr('data-animation');
                        $(this).addClass(animation).addClass('animated');
                    });

                    // Recalculate waypoint triggers because we may h.ave moved some things around.
                    setTimeout(function() {
                        Waypoint.refreshAll();
                    }, 250);

                    // Destory when the animation has triggered once.
                    this.destroy();
                },
                offset
            });
        }
    }

    getHeadlineAnimationElements($element) {
        let animationClass = 'fadeInFastAndSlideInLeft';
        let animationClassBig = 'bigFadeInAndSlideInLeft';
        if ($element.hasClass('align-right')) {
            animationClass = 'fadeInAndSlideInRight';
            animationClassBig = 'bigFadeInAndSlideInRight';
        }

        let elementsToAnimate = [{
            elements: $element.find('.headline-group .subtitle, .headline-group .title'),
            animation: animationClass
        }, {
            elements: $element.find('.headline-group .rule'),
            animation: animationClassBig
        }];

        return elementsToAnimate;
    }

    isElementInViewport($element) {
        let el = $element[0];
        let rect = el.getBoundingClientRect();
        // 100px is an arbitrary cutoff to still animate elements that are barely in view.
        let height = (window.innerHeight || document.documentElement.clientHeight) - 100;

        let topIsVisible = (rect.top >= 0) && (rect.top <= height);
        let bottomIsVisible = (rect.bottom > 0) && (rect.bottom <= height);

        return topIsVisible || bottomIsVisible;
    }

    reset() {
        document.querySelectorAll('.animated').forEach((element, index) => {
            let $element = $(element);

            $element.removeClass('animated');
            let animationClass = $element.attr('data-animation');
            $element.removeClass(animationClass);
        });
        Waypoint.destroyAll();
        this.init();
    }
}

export let animation = new Animation();
