import $ from 'jquery';
require('jquery-pjax');

class FAQ {

    constructor() {
        if (document.querySelectorAll('[data-faq-list]').length) {
            this.init();
        }
    }

    init() {
        $(document).ready(function($) {
            // On form submit, fire off the appropriate pjax request
            $('[data-faq-list] form').on('submit', function(event) {
                $.pjax.submit(event, '[data-faq-results]', {fragment: '[data-faq-results]', scrollTo: false});
            });

            // Fade the container back while AJAX is underway
            $('[data-faq-results]').on('pjax:send', function(event, xhr, options) {
                $(options.container).css('opacity', 0.1);
            });

            // Return to normal opacity when complete.
            $('[data-faq-results]').on('pjax:complete', function(event, xhr, textStatus, options) {
                $(options.container).css('opacity', 1);
            });

            $('.faq-answer').on('transitionend webkitTransitionEnd oTransitionEnd', function () {
                if ($(this).hasClass('active')) {
                    $(this).removeAttr('aria-hidden');
                    $(this).removeAttr('hidden');
                } else {
                    $(this).attr('aria-hidden','true');
                    $(this).prop('hidden','hidden');
                }
            });
        });
    }
}

export let faq = new FAQ();

