import $ from 'jquery';

class MegaMenu {

    constructor() {
        this.pollMagic=null;
        this.magicMoving = false;
        
        $(".nav-global__list").append("<div id='magic-line'></div>");
        this.$magicLine = $("#magic-line");

        $(document).ready(() => {
            let self = this;
            this.megaMenu('.nav-global');

            if ($(".nav-global .active").outerWidth()) {
                this.$magicLine
                    .width($(".nav-global .active").outerWidth())
                    .css("left", $(".nav-global .active a").position().left)
                    .data("origLeft", this.$magicLine.position().left)
                    .data("origWidth", this.$magicLine.outerWidth());
            } else {
                this.$magicLine
                    .width(0)
                    .data("origLeft", 0)
                    .data("origWidth", 0);
            }
            let hoverMagic = null;
            $(".nav-global__list li a:not(.button)").hover(function() {
                let $el = $(this);
                    let leftPos = $el.parent().find('a').first().position().left;
                    let newWidth = $el.parent().outerWidth();
                    self.magicMoving = true;
                    self.$magicLine.stop().animate({
                        left: leftPos,
                        width: newWidth
                    });
            }, function() {
                let $el = $(this);
                self.magicMoving = false;
                if ($(this).parent().hasClass('hovered')) {
                    self.pollMagicLine($(this).parent());
                } else {
                    self.$magicLine.stop().animate({
                        left: self.$magicLine.data("origLeft"),
                        width: self.$magicLine.data("origWidth")
                    });
                }
            });
        
        });
    }

    megaMenu(menuSelector) {
        let triggers = $(menuSelector + ' .trigger');
        let contents = $(menuSelector + ' .content');
    
        $(menuSelector + ' .trigger').focusin( function(){
            let index = $(triggers).index(this);
            if ($(this).hasClass('open')) {
                $(this).addClass('hovered');
            } else {
                $(menuSelector + ' .trigger').removeClass('open').removeClass('hovered');
                $(this).addClass('open').addClass('hovered');
            }
        });
        
        $(menuSelector + ' .trigger').keypress('[data-skip-link]', function(e) {
            if(e.keyCode === 13) {
                let index = $(triggers).index(this);
                $(contents).eq(index).fadeIn(200, 'easeInQuad');
                let focusables = $(contents).eq(index).find('a').first();
                focusables.focus();
                $(contents).eq(index).addClass('hovered');
            }
        });
        
        $(menuSelector + ' .content').focusout( function(){
            let index = $(contents).index(this);
            let container = this;
            setTimeout(function() {
                if ($(container).has(document.activeElement).length <= 0) {
                    $(container).removeClass('hovered');
                    $(container).fadeOut(100, 'easeOutQuad');
                }
            }, 0);
        });
            
        $(menuSelector + ' .content').keypress('[data-skip-link]', function(e) {
            if(e.keyCode === 13) {
                let index = $(contents).index(this);
                $(this).removeClass('hovered');
                $(this).fadeOut(100, 'easeOutQuad');
                let focusables = $(triggers).eq(index).find('a').first();
                focusables.focus();
            }
        });
        
        $(menuSelector + ' .trigger').hoverIntent({
            over: function() {
                let index = $(triggers).index(this);
                if ($(this).hasClass('open')) {
                    $(this).addClass('hovered');
                } else {
                    $(menuSelector + ' .trigger').removeClass('open').removeClass('hovered');
                    $(this).addClass('open').addClass('hovered');
                    setTimeout(function() {
                        $(contents).eq(index).fadeIn(200, 'easeInQuad');
                    }, 200);
                }
            },
            out: function() {
                let index = $(triggers).index(this);
                $(this).removeClass('hovered');
                if ($(contents).eq(index).hasClass('hovered')) {
                    // no action
                } else {
                    $(this).removeClass('open');
                    $(contents).eq(index).fadeOut(100, 'easeOutQuad');
                    $(menuSelector + ' .content' + ' .open').removeClass('open');
                }
            },
            timeout: 500
        });
    
        $(menuSelector + ' .content').hoverIntent({
            over: function() {
                $(this).addClass('hovered');
            },
            out: function() {
                let index = $(contents).index(this);
                $(this).removeClass('hovered');
                if ($(triggers).eq(index).hasClass('hovered')) {
                    // no action
                } else {
                    $(this).removeClass('hovered');
                    $(menuSelector + ' .content' + ' .open').removeClass('open');
                    $(this).fadeOut(100, 'easeOutQuad');
                    $(triggers).eq(index).removeClass('open').removeClass('hovered');
                }
            },
            timeout: 500,
            interval: 10
        });
    }

    pollMagicLine(item) {
        clearTimeout(this.pollMagic)
        this.pollMagic = setTimeout(() => {
            if (item.hasClass('open')) {
                this.pollMagicLine(item);
            } else {
                if (!this.magicMoving) {
                    this.$magicLine.stop().animate({
                        left: this.$magicLine.data("origLeft"),
                        width: this.$magicLine.data("origWidth")
                    });
                    return false;
                }
            }
        }, 100);
    }
}

export let megaMenu = new MegaMenu();
