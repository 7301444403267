import $ from 'jquery';
import Pikaday from 'pikaday';
import {DateTime} from "luxon";
import Modernizr from 'modernizr';
import Plyr from 'plyr';


require('jquery-pjax');
// @todo-upgrade Is this import working?
//window.Visibility = require('visibilityjs/lib/visibility.core');
import Visibility from 'visibilityjs';

require('foundation-sites/js/foundation/foundation.js');
require('foundation-sites/js/foundation/foundation.accordion.js');
require('foundation-sites/js/foundation/foundation.reveal.js');
require('foundation-sites/js/foundation/foundation.slider.js');
require('foundation-sites/js/foundation/foundation.tab.js');

class Skin {

    constructor() {
        // Re-init pjax due to history polyfill
        if ($.support.pjax) {
            $.pjax.enable();
        } else {
            $.pjax.disable();
        }

        $(document).foundation();

        var lastScrollTop = 0,
            scrollDelta = 100,
            trackScrolling = false;

        $(document).ready(function ($) {
            if (!Modernizr.svg) {
                $('img[src$=".svg"]:not(".svg")').each(function () {
                    $(this).attr('src', $(this).attr('src').replace('.svg', '.png'));
                });
            }

            $('#nav-secondary-placeholder').css('height', $('#nav-secondary').outerHeight(true));


            new Waypoint({
                element: '#content-wrap',
                handler(direction) {
                    trackScrolling = !trackScrolling;

                    if (direction === 'down') {
                        $('#header-outer-wrap').addClass('offscreen');
                        $('#nav-secondary').addClass('stuck');
                    } else {
                        $('#header-outer-wrap').removeClass('offscreen');
                        $('#nav-secondary').removeClass('stuck');
                    }

                    $('#header-outer-wrap').removeClass('stuck');
                    $('#nav-secondary').removeClass('scrolling');
                },
                offset: -30
            });

            $('#local-menu-button').bind('click focus', function () {
                $('#local-menu').toggleClass('expanded');
                $('#local-menu-button .icon').toggleClass('icon-anglebracket-down');
                $('#local-menu-button .icon').toggleClass('icon-anglebracket-up');
            });

            $('body').on('addtovisit:opening', function () {
                pauseVideo();
            });
            $('body').on('addtovisit:closed', function () {
                playVideo();
            });

            Visibility.change(function (e, state) {
                if (Visibility.hidden()) {
                    pauseVideo();
                } else {
                    playVideo();
                }
            });

            document.addEventListener('loadeddata', function (e) {
                var $element = $(e.target);
                $element.parents('.bh__video .bh__background').removeClass('lazyload').addClass('lazyloaded').css('background-image', 'none');
            }, true);

            function pauseVideo() {
                var videos = document.getElementsByTagName("video");
                for (var i = 0; i < videos.length; i++) {
                    videos[i].pause();
                }
            }

            function playVideo() {
                $('.bh__video .bh__background').each(function () {
                    var videos = this.getElementsByTagName("video");
                    for (var i = 0; i < videos.length; i++) {
                        videos[i].play();
                    }
                });
            }

            $(window).scroll(function (event) {
                var st = $(this).scrollTop();
                if (Math.abs(lastScrollTop - st) <= scrollDelta)
                    return;
                if (trackScrolling && (lastScrollTop > st)) {
                    // $('#header-outer-wrap').addClass('transition-top');
                    $('#header-outer-wrap').addClass('stuck');
                    $('#nav-secondary').addClass('scrolling');
                } else {
                    $('#header-outer-wrap').removeClass('stuck');
                    $('#nav-secondary').removeClass('scrolling');
                    // setTimeout(function() {
                    //     $('#header-outer-wrap').removeClass('transition-top');
                    // }, 250);
                }
                lastScrollTop = st;
            });

            // fancybox
            $(".fancybox").fancybox({
                padding: 0,
                margin: [70, 20, 20, 20],
                maxWidth: 940,
                helpers: {
                    title: {
                        type: 'outside'
                    },
                    overlay: {
                        locked: false,
                        css: {
                            'background': 'rgba(0, 0, 0, 0.85)'
                        }
                    }
                },
                beforeShow: function () {
                    $.fancybox.wrap.bind("contextmenu", function (e) {
                        return false;
                    });

                    // idea from http://jsfiddle.net/2LXNh/
                    var title = this.element.find('img').attr('title');
                    if (title) {
                        var alt = this.element.find('img').attr('alt');
                        var altHTML;
                        if (alt !== null) {
                            altHTML = '<span class="sub-caption">' + alt + '</span>';
                        }
                        this.title = title + altHTML;
                    }
                },
                afterShow: function () {
                    $('.fancybox-wrap').focus();
                }
            });

            if (window.matchMedia("only screen and (min-width: 1024px)").matches) {
                $(".fancybox-iframe").fancybox({
                    type: 'iframe',
                    padding: 0,
                    margin: [70, 20, 20, 20],
                    maxWidth: 940,
                    helpers: {
                        title: {
                            type: 'outside'
                        },
                        overlay: {
                            locked: false,
                            css: {
                                'background': 'rgba(0, 0, 0, 0.85)'
                            }
                        }
                    },
                    beforeShow: function () {
                        $.fancybox.wrap.bind("contextmenu", function (e) {
                            return false;
                        });

                        // idea from http://jsfiddle.net/2LXNh/
                        var title = this.element.find('img').attr('title');
                        if (title) {
                            var alt = this.element.find('img').attr('alt');
                            var altHTML;
                            if (alt !== null) {
                                altHTML = '<span class="sub-caption">' + alt + '</span>';
                            }
                            this.title = title + altHTML;
                        }
                    },
                    afterShow: function () {
                        $('.fancybox-wrap').focus();
                    }
                });
            }

            // If the big header video is not in a carousel, start it playing.
            $('.bh__video .bh__background').each(function () {
                if ($(this).parents('.carousel-container').length === 0) {
                    var videos = this.getElementsByTagName("video");
                    for (var i = 0; i < videos.length; i++) {
                        videos[i].play();
                    }
                }
            });

            // add play video icon
            // todo_ben I think this is a temporary solution, we might need to add those class directly to the markup instead of adding them using JS.
            $('<div class="vig__play"></div>').insertAfter('.gallery-grid-item img.video-thumbnail');
            $('.gallery-grid-item img.video-thumbnail').parent().addClass('fancybox-video-simple');

            // Initialize date picker
            $('[data-datepicker]').each(function () {
                var field;
                if ($(this).is('input[type=text]')) {
                    field = this;
                } else {
                    field = $(this).find('input[type=text]').eq(0);
                }




                var picker = new Pikaday({
                    field: field[0],
                    trigger: this,
                    minDate: new Date(),
                    firstDay: 0,
                    reposition: false,
                    onSelect: function () {
                        field[0].value = DateTime.fromJSDate(this.getDate()).toFormat('M/d/yyyy');
                        $('.next-link.small').removeAttr('style');

                        $('[href="#step2"].next-link').addClass('pulse')
                        $('[href="#step2"].next-link .text-cell').html('Continue');

                    },
                    onOpen: function () {
                        setTimeout(function () {
                            var ariaLabel = $(field).attr('aria-label');
                            $(field).removeAttr('aria-label');
                            $(field).attr('prev-aria-label', ariaLabel);

                            var relatedLabel = $('label[for=' + field.attr('id') + ']');
                            relatedLabel.attr({
                                'prev-for': relatedLabel.attr('for'),
                            }).removeAttr('for');
                        }, 1000);
                    },
                    onClose: function () {
                        var ariaLabel = $(field).attr('prev-aria-label');
                        $(field).removeAttr('prev-aria-label');
                        $(field).attr('aria-label', ariaLabel);

                        var relatedLabel = $('label[prev-for=' + field.attr('id') + ']');
                        relatedLabel.attr({
                            'for': relatedLabel.attr('prev-for'),
                        }).removeAttr('prev-for');

                    }
                });

                //$(this).data('picker',picker);



                if(field.attr('value')){
                    var dateFromField = DateTime.fromFormat(field.attr('value'),'M/d/yyyy');
                    picker.setDate(dateFromField.toFormat('yyyy-M-d'),false)
                }

                // Toggle picker visibility for keyboard navigation.
                $(field).focus(function () {
                    if (!picker.isVisible()) {
                        picker.show();
                    }
                });
                $(field).blur(function () {
                    if (picker.isVisible()) {
                        picker.hide();
                    }
                });
            });

            var $grid = $('.gallery-grid').imagesLoaded(function () {
                $grid.masonry({
                    // transitionDuration: 0,
                    itemSelector: '.gallery-grid-item',
                    columnWidth: '.gallery-grid-sizer',
                    gutter: 0,
                    percentPosition: true
                });
            });

            // footer link accordion for mobile
            if ($(window).width() <= 767) {
                $('.footer-link-container').hide();
                $('.footer-link-container.content-open').show();
                $('.column-header').click(function () {
                    $('.footer-link-container').slideUp(300);
                    $('.footer-link-container').removeClass('content-open');

                    if ($(this).hasClass('title-open')) {
                        $('.column-header').removeClass('title-open');
                        $(this).next().slideUp(300);
                        $(this).removeClass('title-open');
                    } else {
                        $('.column-header').removeClass('title-open');
                        $(this).next().slideDown(300);
                        $(this).addClass('title-open');
                    }
                });
            }

            // fancybox for membership level (for mobile)
            if ($(window).width() <= 600) {
                $(".fancybox-membership").fancybox({
                    //closeBtn: false,
                    padding: 0,
                    margin: [70, 20, 20, 20],
                    minWidth: 300,
                    helpers: {
                        overlay: {
                            css: {
                                'background': '#030c26'
                            }
                        }
                    },

                    beforeShow: function () {
                        $(".fancybox-skin").css("backgroundColor", "#f0f0f0");
                        //$('<ul class="fancybox-membership-note"><li>one car per visit</li><li>All household and above levels include adminsion for children under the age of living in member household</li><li>Free admission at more than 300 science centers and Museum outside Chicago and around the world within the ASTC Network</li><ul>').insertAfter('.fancybox-skin');
                    }
                });
            } else {
            }

            // fancybox for exhibit home sidebar (for all essentials)
            $(".fancybox-essentials").fancybox({
                padding: 20,
                margin: [70, 20, 20, 20],
                minWidth: 300,
                helpers: {
                    overlay: {
                        css: {
                            'background': 'rgba(0, 0, 0, 0.85)'
                        }
                    }
                },

                afterClose: function () {
                    $("#all-essentials").show();
                }
            });

            if ($('[data-hours-tomorrow], [data-hours-today]').length) {
                // @todo-upgrade Switched to Luxon
                var now = DateTime.local();
                var time = parseInt(now.setZone('America/Chicago').toFormat('Hmm'));
                if (time > 1530) {
                    $('[data-hours-tomorrow]').show();
                } else {
                    $('[data-hours-today]').show();
                }
            }

            // If we have a lazyload container, listen for the child loading and remove the class.
            $('.lazyload-child img.lazyload').on('load', function () {
                $(this).parent('.lazyload-child').removeClass('lazyload-child');
            });

            // Register experience teasers hover only above 1024
            if ($(window).width() >= 1024) {
                MSI.UI.registerBoxTeaserEventHandler($('.box-teaser'));
            }

            $('.topright-menu__list__item--read-more').hoverIntent({
                over: function() {
                    if ($(this).hasClass('open')) {
                        $(this).addClass('hovered');
                    } else {
                        $(this).addClass('open').addClass('hovered');
                    }
                },
                out: function() {
                    $(this).removeClass('hovered');
                    $(this).removeClass('open');

                },
                timeout: 500
            });

        });

        $(window).load(function () {
            Waypoint.refreshAll();
        });

        // Benefits CE

        $.fn.extend({
            toggleText: function (a, b) {
                return this.text(this.text() == b ? a : b);
            }
        });

        $('#benefits_ce__list-item__show-more').on('click', function () {
            $('.benefits_ce__list-items').toggleClass('benefits_ce__list-items--open');
            setTimeout(function () {
                $(".benefits_ce__list-item__show-more-link").toggleText('Show more', 'Show less');
            }, 400);
        });

        // Table accordion
        $('.table-accordion').each(function (index, element) {
            $(element).find('.table-accordion__title').on('click', function () {
                $(this).next().toggleClass('table-accordion__panel--open');
                $(this).find('.icon').toggleClass('icon-anglebracket-up', 400);

            });
        })


        $('body').on('click', '[data-visit-preview-uid]', function (event) {
            event.preventDefault();
            var uid = $(this).attr('data-visit-preview-uid');
            $.ajax({
                url: '/visit-sidebar.html?tx_experiences_addtovisit[action]=preview&tx_experiences_addtovisit[uid]=' + uid,
                method: 'post',
                success: function(result) {
                    $.fancybox.open(result);
                    setTimeout(function(){
                        // let videoTeaser = Plyr.setup('.pav__preview-add-visit .js-player', {controls: ['play-large','play','mute','progress','fullscreen']});
                    },100);
                },
                error: function() {

                }
            });
        })


        //['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
        let players = Plyr.setup('.js-player', {controls: ['play-large','play', 'mute','progress', 'current-time','fullscreen']});

    }
}

export let skin = new Skin();
