import $ from 'jquery';
import { DateTime } from "luxon";
import { user } from './user.js';

class Visit {

    constructor() {
        this.loadSidebar();

        $(document).ready(() => {
            this.init();
        });
    }

    loadSidebar() {
        var cart = user.getCart();
        var visitSidebarUrl = '/empty-visit-sidebar.html';
        var method = 'get';

        if (cart.length > 0) {
            var cartUids = cart.join();
            visitSidebarUrl = '/visit-sidebar.html?tx_experiences_addtovisit[cart]=' + cartUids;
        }

        /*
         * Load Cart on page load
         */
        $.ajax({
            url: visitSidebarUrl,
            method: method,
            success: function(result) {
                $(document).ready(function($) {
                    $("#my-visit-container").html(result);
                    var count = $("[data-cart-count]").attr("data-cart-count");

                    if (count > 0) {
                        $("[data-cart-button-count]").html(count);

                        // Dispatch event so that mobile drawer can consume it.
                        window.dispatchEvent(new CustomEvent("addToVisitSidebarReady"));

                        if (!$("#my-visit-opener").is(':visible') || !$("#my-visit-opener-mobile").is(':visible')) {
                            $('body').addClass('my-visit-button-shown');
                            $("[data-cart-open-container]").addClass('my-visit-button-appear');
                            $("#my-visit-opener").show();
                            $("#my-visit-opener-mobile").css('display', 'table');
                        }
                    }

                    // @todo @ticketure Can we assume that whatever is in the Ticketure cookie is correct?

                });
            }
        });
    }

    openSidebar() {
        $('body').trigger('addtovisit:opening');

        $('#my-visit-wrap').show();
        $('.overlay-exit').show();
        $('#my-visit-wrap').addClass('open');
        $('#my-visit-container').addClass('open');


        $('[data-cart-more-trigger]').show();

        $('body').css('overflow', 'hidden');

        $("#my-visit").addClass('opening-container');
        $("#my-visit .experience-wrap").addClass('opening-container delay-children');
        setTimeout(() => {
            $("#my-visit").removeClass('opening-container').addClass('open-container');
            $("#my-visit .experience-wrap").removeClass('opening-container delay-children').addClass('open-container');

            $('body').trigger('addtovisit:opened');
        }, 500);
        $("#my-visit-container").attr("tabindex",-1).focusWithoutScrolling();
        $("[data-cart-close]").attr("tabindex",0);
    }

    updateDuration() {
        var cart = user.getCart();
        var cartUids = 0;
        if (cart.length > 0) {
            cartUids = cart.join();
        }
        var durationUrl = '/visit-sidebar.html?tx_experiences_addtovisit[action]=duration&tx_experiences_addtovisit[cart]=' + cartUids;

        $.ajax({
            url: durationUrl,
            method: 'get',
            success: function(result) {
                console.log(result);
                $('[data-visit-duration').html(result);
            },
            error: function() {
                console.log('nope');
                $('[data-visit-duration').html('');
            }
        });
    }

    /*
    * Remove an Experience from List
    */
    removeExperience(that, successCallback) {
        $(that).parents(['data-addtovisit-uid']).addClass('item-removed');
        var uid = $(that).attr('data-addtovisit-delete-uid');
            $.ajax({
                url: '/visit-sidebar.html?tx_experiences_addtovisit[action]=delete&tx_experiences_addtovisit[uid]=' + uid,
                method: 'post',
                success: function(result) {
                    let ticketureId = $(that).parents('[data-addtovisit-uid]').attr('data-addtovisit-ticketureid');
                    user.removeFromCart(uid, ticketureId);

                    $('[data-addtovisit-uid="'+uid+'"]').remove();

                    $(that).parents('[data-addtovisit-uid]').remove();
                    var hasExperiencesWithDateRequired = $('[data-addtovisit-uid][data-addtovisit-requiresdateselection]').length > 0;
                    if (!hasExperiencesWithDateRequired) {
                        user.removeVisitDate();
                    }
                    var count = $('[data-addtovisit-uid]').length;
                    $("[data-cart-button-count]").html(count);
                    // Cart is now empty, toggle visibility
                    if (parseInt(count) === 0) {
                        $('[data-has-cart-content]').hide();
                        $('[data-empty-cart-content]').show();
                    }

                    successCallback();
                },
                error: function() {
                    $(that).parents(['data-addtovisit-uid']).animate({
                        opacity: '1.0'
                    });
                }
            });

    }

    closeSidebar() {
        $('body').trigger('addtovisit:closing');

        $('#my-visit-wrap').removeClass('open-more').removeClass('open');
        $('#my-visit-container').removeClass('open');

        setTimeout(function() {
            $("#my-visit").removeClass('opening-container open-container');
            $("#my-visit .experience-wrap").removeClass('opening-container open-container delay-children');
            $("#more-visit").removeClass('opening-container open-container delay-children');
        }, 500);

        setTimeout(function() {
            $('.overlay-exit').hide();
            $('body').trigger('addtovisit:closed');
        }, 500);
        $('body').css('overflow', 'auto');

        // If cart button is not present and there are items in the cart, we need to make the button appear
        if (!$("[data-cart-open-container]").hasClass('my-visit-button-appear')) {
            var count = $('#my-visit-container [data-addtovisit-uid]').length;
            if (count > 0) {
                $("[data-cart-button-count]").html(count);
                setTimeout(function() {
                    $('body').addClass('my-visit-button-shown');
                    $("[data-cart-open-container]").addClass('my-visit-button-appear');
                    $("#my-visit-opener").show();
                    $("#my-visit-opener-mobile").css('display', 'table');
                }, 750);
            }
        }
        $("#my-visit-container").removeAttr("tabindex");
        $("[data-cart-close]").removeAttr("tabindex");
    }

    init() {
        let self = this;
        /*
        * Add Experience to List
        */
        $('body').on('click', '[data-addtovisit-add-uid]', function (e) {
            e.preventDefault();
            //$('[data-empty-cart-item]').show().addClass('incoming-item');

            // Cart is now empty, but will not be. Toggle visibility
            var count = $('#my-visit-container [data-addtovisit-uid]').length;
            if (parseInt(count) === 0) {
                $('[data-has-cart-content]').show();
                $('[data-empty-cart-content]').hide();
            }

            var sidebarRightAttr = $("#my-visit-wrap").css("right");

            if (sidebarRightAttr !== 0) {
                if (!window.dontOpenSideBar) {
                    $.fancybox.close();

                    self.openSidebar();
                }
            }
            var uid = $(this).attr('data-addtovisit-add-uid');
            if($('#my-visit .experience-wrap [data-addtovisit-uid="'+uid+'"]').length == 0){ //avoid to add the same event more than once
                var cart = localStorage.MSICart;

                var selectedAddDate = $(this).attr('data-addtovisit-add-date');
                if (selectedAddDate) {
                    date = DateTime.fromFormat(selectedAddDate, 'M/d/yyyy', { zone: "America/Chicago" });
                    user.setVisitDate(date);
                    window.keepSidebarOpen = true;
                    $.fancybox.close();
                    window.keepSidebarOpen = false;
                } else {

                    // Look on the page for something that might tell us the date.
                    // @todo What should we do if there's already a date saved in the cart?
                    if ($('[data-addtovisit-date]').length) {
                        var dateValue = $('[data-addtovisit-date]').val();
                        if (dateValue) {
                            date = DateTime.fromFormat(date, 'M/d/yyyy', {zone: 'America/Chicago'});
                            user.setVisitDate(date);
                        }
                    }
                }

                var date = user.getVisitDate();
                var visitSidebarUrl = '/visit-sidebar.html?tx_experiences_addtovisit[action]=add&tx_experiences_addtovisit[uid]=' + uid + '&tx_experiences_addtovisit[cart]=' + cart;


                if (date && (typeof date !== 'undefined')) {
                    date = DateTime.fromFormat(date, 'yyyy-M-d', {zone: 'America/Chicago'}).toFormat('M/d/yyyy');
                    visitSidebarUrl += "&tx_experiences_addtovisit[date]=" + date;
                }

                $.ajax({
                    url: visitSidebarUrl,
                    method: 'post',
                    success: function(result) {
                        let ticketureId = $(result).attr('data-addtovisit-ticketureid');
                        user.addToCart(uid, ticketureId);
                        $('[data-empty-cart-item]').after(result);
                        setTimeout(function() {
                            $('[data-addtovisit-uid].new-item').addClass('incoming-item').removeClass('new-item');
                        }, 1);
                        setTimeout(function() {
                            $('[data-addtovisit-uid].incoming-item').removeClass('incoming-item');
                        }, 800);

                        var count = $('my-visit-container [data-addtovisit-uid]').length;
                        $("[data-cart-button-count]").html(count);

                        // if there's a matching experience in the more sidebar, remove that
                        $('#more-visit [data-addtovisit-add-uid=' + uid + '] .box-teaser').addClass('item-removed');
                        setTimeout(function() {
                            $('#more-visit [data-addtovisit-add-uid=' + uid + ']').hide();
                        }, 1000);

                        document.querySelectorAll('[x-data].sticky-footer-wrapper')[0].__x.getUnobservedData().items.push(result);
                        // Cart is no longer empty, but we already toggled visiblity in preparation of success

                        self.updateDuration();
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        if (jqXHR.responseText) {
                            $.fancybox({
                                content: jqXHR.responseText,
                                beforeClose: function() {
                                    if (!window.keepSidebarOpen) {
                                        self.closeSidebar();
                                    }
                                }
                            });
                        } else {
                            if (errorThrown == 'Conflict') {
                                $('[data-empty-cart-item]').addClass('item-removed');
                                $('[data-addtovisit-uid=' + uid + ']').removeClass('animated shake');
                                setTimeout(function() {
                                    $('[data-addtovisit-uid=' + uid + ']').addClass('animated shake');
                                }, 1);
                                setTimeout(function() {
                                    $('[data-empty-cart-item]').hide().removeClass('item-removed');
                                    $('[data-addtovisit-uid=' + uid + ']').removeClass('animated shake');
                                }, 1000);
                            } else {
                                $('[data-empty-cart-item]').addClass('item-removed');
                                setTimeout(function() {
                                    $('[data-empty-cart-item]').hide().removeClass('item-removed');
                                    $('[data-addtovisit-uid=' + uid + ']').removeClass('animated shake');
                                }, 5000);

                                // Cart is still empty. Toggle visibility
                                var count = $('[data-addtovisit-uid]').length;
                                if (parseInt(count) === 0) {
                                    $('[data-has-cart-content]').hide();
                                    $('[data-empty-cart-content]').show();
                                }

                            }
                        }
                    }
                });
            }
        });



        $("#my-visit-container,[data-mobile-cart-items-list]").on('click', '[data-addtovisit-delete-uid]', function(){
            self.removeExperience(this, function() {
                self.updateDuration();
            });
        });
        $("#my-visit-container,[data-mobile-cart-items-list]").on('keypress', '[data-addtovisit-delete-uid]', function(e) {
            if(e.keyCode === 13) {
                self.removeExperience(this, function() {
                    self.updateDuration();
                });
            }
        });

        $("#my-visit-container,[data-mobile-cart-items-list]").on('keypress', '[data-link-url]', function(e) {
            if(e.keyCode === 13) {
                var url = $(this).attr('data-link-url');
                location.href=url;
            }
        });
        // todo: the following open and close event functions have temporary animations. consider refining this.

        /*
        * Open Sidebar
        */
        $('[data-cart-open]').click(() => {
            if ($("#my-visit-wrap").hasClass("open")) {
                self.closeSidebar();
            } else {
                self.openSidebar();
            }
        });

        $.fn.focusWithoutScrolling = function() {
            // var x = window.scrollX, y = window.scrollY;
            var x = $(document).scrollLeft(), y = $(document).scrollTop();
            // @todo-upgrade Not working after ES6 updates.
            // this.focus();
            window.scrollTo(x, y);
            return this;
        };



        /*
        * Close Sidebar
        */
        $("#my-visit-container").on('click', '[data-cart-close]', self.closeSidebar);
        $("#my-visit-container").on('keypress', '[data-cart-close]', (e) => {
            if(e.keyCode === 13) {
                self.closeSidebar();
            }
        });


        /*
        * Open More
        */
        $("#my-visit-container").on('click', '[data-cart-more-trigger]', openMoreSidebar);

        function openMoreSidebar() {
            $('#my-visit-wrap').addClass('open-more');
            $("#more-visit").addClass('opening-container delay-children');
            $('#my-visit-wrap').removeClass('open');
            setTimeout(function() {
                $("#more-visit").removeClass('opening-container delay-children').addClass('open-container');
            }, 500);
        }
    }
}

export let visit = new Visit();
