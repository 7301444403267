/*! slideUp transition plugin for Cycle2 */
(function($) {
"use strict";

$.fn.cycle.transitions.slideUp = {
    before: function( opts, curr, next, fwd ) {
        opts.API.stackSlides( opts, curr, next, fwd );

        if ($('html').hasClass('cssanimations')) {
            var animationClasses = 'animated groupedSlideUpOut';
            if ($(curr).find('.feature-quote').length) {
                $(curr).find('.feature-quote .icon').removeClass('groupedSlideUpIn').addClass(animationClasses);
                $(curr).find('.feature-quote .quote-content').removeClass('groupedSlideUpIn').addClass(animationClasses);
                $(curr).find('.feature-quote cite').removeClass('groupedSlideUpIn').addClass(animationClasses);
            }
            if ($(next).find('.feature-quote').length) {
                $(next).find('.feature-quote .icon').addClass('animated groupedSlideUpIn');
                $(next).find('.feature-quote .quote-content').addClass('animated groupedSlideUpIn');
                $(next).find('.feature-quote cite').addClass('animated groupedSlideUpIn');
            }
        } else {
            // Copied from scrollVertUp
            var height = opts.container.css('overflow','hidden').height();
            opts.cssBefore = { bottom: fwd ? -height : height, top: fwd ? height : -height, left: 0, opacity: 1, display: 'block', visibility: 'visible' };
            opts.animIn = { bottom: fwd ? -height : height, top: 0 };
            opts.animOut = { bottom: fwd ? height : -height, top: fwd ? -height : height };
        }
    },

    after: function( opts, curr, next, fwd ) {
        var animationClasses = 'animatable animated groupedSlideUpOut';
        $(curr).find('.feature-quote .icon').removeClass(animationClasses);
        $(curr).find('.feature-quote .quote-content').removeClass(animationClasses);
        $(curr).find('.feature-quote cite').removeClass(animationClasses);
    }
};

})(jQuery);
